<template>
  <div class="memdig">
    <!-- 成员 -->
    <div class="memdig_center">
      <div class="h_bent" v-if="this.activeName=='first'">
        <el-input
          placeholder="请输入成员姓名"
          suffix-icon="el-icon-search"
          v-model="membername"
          size="mini"
          @change="queryUserMsgByNameEnterCircle"
          clearable
        ></el-input>
        <el-button type="primary" @click="queryUserMsgByNameEnterCircle" size="mini">搜索</el-button>
      </div>
      <div v-else class="h_bent">
        <el-input
          placeholder="请输入成员姓名"
          suffix-icon="el-icon-search"
          v-model="membername1"
          @change="queryUserMsgByNameNoEnterCircle"
          size="mini"
          clearable
        ></el-input>
        <el-button type="primary" @click="queryUserMsgByNameNoEnterCircle" size="mini">搜索</el-button>
      </div>
      <div class="abc">
        <p>
          <i class="memicon"></i>
          <span v-if="this.activeName=='first'">{{alsomember}}人</span>
          <span v-else>{{nomember}}人</span>
        </p>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="已加入成员" name="first">
          <div v-if="!cities">
            <el-empty description="暂无成员"></el-empty>
          </div>
          <div v-else>
            <header>
              <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" size="mini">全选</el-checkbox>
              <el-button type="primary" size="mini" @click="deleteCircleMember" style="margin-left:15px">移出教研圈</el-button>
            </header>
            <main>
              <!-- <div style="margin: 15px 0;"></div> -->
              <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                <el-checkbox v-for="item in cities" :label="item.userId" :key="item.userId">
                  <div class="acflex">
                    <img src="../../../../../../assets/components/userin.png" alt />
                  </div>
                  <p>{{item.userName}}</p>
                </el-checkbox>
              </el-checkbox-group>
            </main>
          </div>
        </el-tab-pane>
        <el-tab-pane label="待加入成员" name="second">
          <div v-if="!cities1">
            <el-empty description="暂无成员"></el-empty>
          </div>
          <div v-else>
            <header>
              <el-checkbox :indeterminate="isIndeterminate1" v-model="checkAll1" @change="handleCheckAllChange1">全选</el-checkbox>
              <div>
                <el-button type="primary" size="mini" @click="addCircleMember">同意加入圈子</el-button>
                <el-button type="danger" size="mini" @click="addNoCircleMember">不同意加入圈子</el-button>
              </div>
              <!-- <p>未加入成员列表</p> -->
            </header>
            <main>
              <!-- <div style="margin: 15px 0;"></div> -->
              <el-checkbox-group v-model="checkedCities1" @change="handleCheckedCitiesChange1">
                <el-checkbox v-for="item in cities1" :label="item.userId" :key="item.userId">
                  <div class="acflex">
                    <img src="../../../../../../assets/components/userin.png" alt />
                  </div>
                  <p>{{item.userName}}</p>
                </el-checkbox>
              </el-checkbox-group>
            </main>
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- <el-button size="mini" type="primary" @click="shutdig">确定</el-button> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    memdata: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      activeName: "first",
      alsomember: null,
      nomember: null,
      // 已加入
      //输入框
      membername: null,
      //多选框已加入成员
      checkAll: false, //全选
      checkedCities: [], //成员选择数据
      cities: null, //成员数据
      isIndeterminate: true, //半选
      // 未加入
      //输入框
      membername1: null,
      //
      checkAll1: false, //全选
      checkedCities1: [], //成员选择数据
      cities1: null, //成员数据
      isIndeterminate1: false, //半选
    };
  },
  created() {
    this.queryCircleEnterMember();
    this.queryCircleNoEnterMember();
  },
  methods: {
    // 查询圈子的已加入人员信息
    async queryCircleEnterMember() {
      const { data, status, message } = await this.$Http.queryCircleEnterMember(
        {
          pageNo: 1,
          pageSize: 100000,
          researchId: this.memdata.id,
        }
      );
      if (status == 200) {
        if (data == null) {
          console.log(data, status, "查询圈子的已加入人员信息");
          // this.$message.warning(`${message}`);
          this.cities = data;
          this.checkAll = false;
          this.alsomember = 0;
        } else {
          // console.log(data, status, "查询圈子的已加入人员信息");
          this.cities = data.list;
          this.alsomember = data.list.length;
        }
      }
    },
    //根据用户名查询用户
    async queryUserMsgByNameEnterCircle() {
      const { data, status, message } =
        await this.$Http.queryUserMsgByNameEnterCircle({
          pageNo: 1,
          pageSize: 100000,
          researchId: this.memdata.id,
          userName: this.membername,
        });
      if (status == 200) {
        if (data == null) {
          // console.log(data, status, "根据用户名查询用户");
          // this.$message.warning(`${message}`);
          this.cities = data;
          this.checkAll = false;
        } else {
          // console.log(data, status, "搜索圈子的已加入人员信息");
          this.cities = data.list;
        }
      }
    },
    // 移出成员
    async deleteCircleMember() {
      const { data, status } = await this.$Http.deleteCircleMember({
        memberIdList: this.checkedCities,
        researchId: this.memdata.id,
      });
      if (status == 200) {
        // console.log(data, status, "移出成员");
        this.$message({
          message: "移除成员成功",
          type: "success",
        });
        this.queryCircleEnterMember();
      }
    },

    // ---
    // 查询待加入人员信息
    async queryCircleNoEnterMember() {
      // let aa = [
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 1,
      //     userName: "第一人21",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 2,
      //     userName: "第一人22",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 3,
      //     userName: "第一人23",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 4,
      //     userName: "第一人24",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 5,
      //     userName: "第一人25",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 6,
      //     userName: "第一人26",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 7,
      //     userName: "第一人27",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 8,
      //     userName: "第一人21",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 9,
      //     userName: "第一人22",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 10,
      //     userName: "第一人23",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 11,
      //     userName: "第一人24",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 12,
      //     userName: "第一人25",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 13,
      //     userName: "第一人26",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 14,
      //     userName: "第一人27",
      //   },
      //     {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 15,
      //     userName: "第一人21",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 16,
      //     userName: "第一人22",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 17,
      //     userName: "第一人23",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 18,
      //     userName: "第一人24",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 19,
      //     userName: "第一人25",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 20,
      //     userName: "第一人26",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 21,
      //     userName: "第一人27",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 22,
      //     userName: "第一人21",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 23,
      //     userName: "第一人22",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 24,
      //     userName: "第一人23",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 25,
      //     userName: "第一人24",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 26,
      //     userName: "第一人25",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 27,
      //     userName: "第一人26",
      //   },
      //   {
      //     authorFlag: 0,
      //     avager: null,
      //     userId: 28,
      //     userName: "第一人27",
      //   },
      // ];
      // this.cities1 = aa;
      // this.nomember = aa.length;
      // return;
      const { data, status, message } =
        await this.$Http.queryCircleNoEnterMember({
          pageNo: 1,
          pageSize: 100000,
          researchId: this.memdata.id,
        });
      if (status == 200) {
        if (data == null) {
          // this.$message.warning(`${message}`);
          this.cities1 = data;
          this.checkAll1 = false;
          this.nomember = 0;
        } else {
          console.log(data, status, "待加入人员信息");
          this.cities1 = data.list;
          this.nomember = data.list.length;
        }
      }
    },
    //未加入圈子成员--根据用户名查询用户
    async queryUserMsgByNameNoEnterCircle() {
      const { data, status, message } =
        await this.$Http.queryUserMsgByNameNoEnterCircle({
          pageNo: 1,
          pageSize: 100000,
          researchId: this.memdata.id,
          userName: this.membername1,
        });
      if (status == 200) {
        if (data == null) {
          // console.log(data, status, "查询圈子的w未加入人员信息");
          // this.$message.warning(`${message}`);
          this.cities1 = data;
          this.checkAll1 = false;
        } else {
          // console.log(data, status, "搜索圈子未加入人员信息");
          this.cities1 = data.list;
        }
      }
    },
    // 同意加入
    async addCircleMember() {
      // console.log(this.checkedCities1, this.memdata.id, "tongyi");
      // return;
      const { data, status } = await this.$Http.addCircleMember({
        memberIdList: this.checkedCities1,
        researchId: this.memdata.id,
      });
      if (status == 200) {
        // console.log(data, status, "同意加入");
        this.$message({
          message: "已同意成员加入",
          type: "success",
        });
        this.checkedCities1 = [];
        this.queryCircleNoEnterMember();
      }
    },
    // 拒绝加入
    async addNoCircleMember() {
      // console.log(this.checkedCities1, this.memdata.id, "tongyi");
      // return;
      const { data, status } = await this.$Http.addNoCircleMember({
        memberIdList: this.checkedCities1,
        researchId: this.memdata.id,
      });
      if (status == 200) {
        // console.log(data, status, "拒绝加入");
        this.$message({
          message: "已拒绝成员加入",
          type: "success",
        });
        this.checkedCities1 = [];
        this.queryCircleNoEnterMember();
      }
    },

    //全选 已加入
    handleCheckAllChange(val) {
      // console.log(val, "全选按钮11");
      console.log(this.cities, "aaa");
      let citys = [];
      for (let x = 0; x < this.cities.length; x++) {
        citys.push(this.cities[x].userId);
      }
      // console.log(citys, "bbb");
      this.checkedCities = val ? citys : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      // console.log(value, "全选数据");
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },

    //全选 待加入
    handleCheckAllChange1(val) {
      // console.log(val, "全选按钮");
      // console.log(this.cities1, "nnn");
      let citys = [];
      for (let x = 0; x < this.cities1.length; x++) {
        citys.push(this.cities1[x].userId);
      }
      // console.log(citys, "bbb");
      this.checkedCities1 = val ? citys : [];
      this.isIndeterminate1 = false;
    },
    handleCheckedCitiesChange1(value) {
      // console.log(value, "全选数据", this.checkedCities1, "123", this.cities1);
      let checkedCount = value.length;
      this.checkAll1 = checkedCount === this.cities1.length;
      this.isIndeterminate1 =
        checkedCount > 0 && checkedCount < this.cities1.length;
    },

    //tabs切换
    handleClick(tab, event) {
      // console.log(tab, event);
      // console.log(typeof tab.index, "tabs标识");
      if (tab.index == 0) {
        this.queryCircleEnterMember();
      } else {
        this.queryCircleNoEnterMember();
      }
    },

    // shutdig() {
    //   this.$confirm("是否确定退出", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       this.$emit("shutdown");
    //     })
    //     .catch(() => {
    //       return;
    //       // this.$message({
    //       //   type: "info",
    //       //   message: "已取消删除",
    //       // });
    //     });
    // },
  },
  watch: {
    memdata: {
      handler(val, oldval) {
        console.log(val, oldval, "成员");
        this.queryCircleEnterMember();
        this.queryCircleNoEnterMember();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.memdig {
  .memdig_center {
    .h_bent {
      padding: 0px 0px 10px 15px;
      .el-input {
        width: 300px;
      }
    }
    .abc {
      position: relative;
      p {
        position: absolute;
        top: 10px;
        right: 16px;
        i {
          width: 14px;
          height: 14px;
          display: inline-block;
          background: url("../../../../../../assets/userinfo/menber.png")
            no-repeat;
          vertical-align: middle;
        }
        span {
          // width: 27px;
          // height: 16px;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #5d6066;
          // line-height: 14px;
          vertical-align: middle;
        }
      }
    }
    .el-tabs {
      padding: 0 15px;
      .el-tabs__content {
        header {
          display: flex;
          justify-content: space-between;
        }
        main {
          height: 290px;
          overflow: auto;
          margin-top: 10px;
          .acflex {
            display: flex;
            justify-content: center;
            margin-top: 10px;
          }
          p {
            width: 90px;
            margin-top: 5px;
            text-align: center;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #5d6066;
            white-space: pre-wrap;
            word-wrap: break-word;
            // line-height: 16px;
            word-break: break-all;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            // overflow: hidden;
          }
        }
      }
    }
  }
}
</style>