<template>
  <div class="yet">
    <div class="yet_center">
      <!-- 已上线的圈子 -->
      <main>
        <el-table :data="cirdata" stripe style="width: 100%" height="475">
          <el-table-column type="index" width="50" align="center" fixed="left" label="序号" :index="computeTableIndex"></el-table-column>
          <el-table-column fixed prop="circleName" align="center" label="教研圈名称" min-width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="periodName" label="学段" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="gradeName" label="年级" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="subjectTypeName" label="学科类型" width="150"></el-table-column>
          <el-table-column prop="subjectName" label="学科" width="150"></el-table-column>
          <el-table-column prop="memberNo" align="center" label="圈子成员数量" width="150"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="200"></el-table-column>
          <el-table-column fixed="right" label="操作" width="300">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="viewdata(scope.row)" style="color: #EB5757;">查看教研圈</el-button>
              <el-button type="text" size="mini" @click="memberdata(scope.row)">成员管理</el-button>
              <el-button type="text" size="mini" @click="listdata(scope.row)">话题帖子管理</el-button>
              <el-button type="text" size="mini" @click="noticedata(scope.row)">公告管理</el-button>
            </template>
          </el-table-column>
        </el-table>
      </main>
    </div>
    <!-- :show-close="false" -->
    <el-dialog
      :title="'成员管理-'+this.membermanage.circleName"
      :visible.sync="memberDialog"
      width="50%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <!-- intoducedata.adminFlag -->
      <mem :memdata="membermanage"></mem>
    </el-dialog>
    <el-dialog
      :title="'话题帖子管理-'+this.tiezidata.circleName"
      :visible.sync="tieziDialog"
      width="50%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="handleClose"
    >
      <mems :memsdata="tiezidata" :replytiezi="replytiezi"></mems>
    </el-dialog>
    <el-dialog
      :title="'公告管理-'+this.noticemanage.circleName"
      :visible.sync="outerDig"
      width="50%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :top="'4vh'"
    >
      <el-dialog
        width="900px"
        title="发布公告"
        :visible.sync="innerDig"
        append-to-body
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        :top="'4vh'"
      >
        <noticen @shutndialog="ssshutnotdialog" :notid="noticemanage.id"></noticen>
      </el-dialog>
      <!-- @shutnotidig="snotdig" -->
      <noticem :notid="noticemanage.id" @opennotidig="ssnotdig"></noticem>
      <!-- <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="surenDig">确定</el-button>
        <el-button @click="snotdig" size="mini">取 消</el-button>
      </div>-->
    </el-dialog>
  </div>
</template>
<script>
import mem from "./component/memdig.vue";
import mems from "./component/memsdig.vue";
import noticem from "./component/noticedig.vue";
import noticen from "./component/newnotice.vue";
export default {
  props: {
    cirdata: {
      type: Array,
      default: null,
    },
    curp: {
      type: Number,
      default: 1,
    },
    psize: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      // 表格数据
      noneData: null,
      // 帖子管理
      tieziDialog: false,
      tiezidata: {},
      replytiezi: false,
      // 成员管理
      memberDialog: false,
      membermanage: {},
      // 查看
      // 公告
      noticemanage: {},

      outerDig: false,
      innerDig: false,
    };
  },
  methods: {
    // ---
    // 帖子管理
    listdata(row) {
      console.log(row, "帖子管理");
      this.replytiezi = false;
      this.tiezidata = row;
      this.tieziDialog = true;
    },
    handleClose(done) {
      console.log("关闭前的回调", done);
      this.$confirm("确认关闭？")
        .then((_) => {
          this.replytiezi = true;
          done();
        })
        .catch((_) => {});
      return;
      this.$confirm("确认关闭？").then(() => {
        done();
      });
      this.replytiezi = true;
    },
    // ----
    // 成员管理
    memberdata(row) {
      console.log(row, "成员管理");
      this.membermanage = row;
      this.memberDialog = true;
    },
    // shutdigs() {
    //   this.memberDialog = false;
    // },
    // -----
    // 查看

    viewdata(row) {
      console.log(row);
      this.$router.push({
        path: "/circleintroduce",
        query: {
          introId: row.id,
          circlesite: 0,
        },
      });
    },
    // ----
    // 公告管理
    noticedata(row) {
      console.log(row, "公告管理");
      this.noticemanage = row;
      this.outerDig = true;
    },
    // snotdig() {
    //   console.log("关闭外弹框");
    //   this.outerDig = false;
    // },
    ssnotdig() {
      console.log("打开内弹框");
      this.innerDig = true;
      this.$store.commit("userinfo/editoras", false);
    },
    ssshutnotdialog() {
      console.log("关闭内弹框");
      this.innerDig = false;
      this.$store.commit("userinfo/editoras", true);
    },
    // surenDig() {
    //   this.$confirm("是否确定退出？", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       this.outerDig = false;
    //     })
    //     .catch(() => {
    //       return;
    //     });
    // },

    //监听index
    computeTableIndex(index) {
      // console.log(index, this.queryInfo.pagenum, this.queryInfo.pagesize, 'e')
      return (this.curp - 1) * this.psize + index + 1;
    },
  },
  components: {
    mem,
    mems,
    noticem,
    noticen,
  },
};
</script>