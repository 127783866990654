<template>
  <div class="memsdig">
    <!-- 帖子管理 -->
    <div class="memsdig_center">
      <nav>
        <div class="div_one">
          <el-input placeholder="请输入话题标题" prefix-icon="el-icon-search" v-model="title" size="mini" @change="querylist"></el-input>
          <el-button size="mini" type="primary" @click="querylist">搜索</el-button>
        </div>
        <div class="div_two">
          <span>发布时间：</span>
          <el-date-picker
            v-model="time"
            type="daterange"
            size="mini"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </div>
        <div class="div_three">
          <el-button @click="querylist" size="mini" type="primary">查询</el-button>
          <el-button size="mini" @click="chognzhi">重置</el-button>
        </div>
      </nav>
      <main>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="我发布的话题" name="first" style="height:630px;overflow-y:auto">
            <div v-if="!clistdata">
              <el-empty description="暂无话题"></el-empty>
            </div>
            <div v-else>
              <div class="mems_one" v-for="item,index in clistdata" :key="index">
                <div class="one_left">
                  <span>{{item.replayCount}}</span>
                </div>
                <div class="one_right">
                  <p class="one_r_title">{{item.title}}</p>
                  <p class="one_r_text">
                    <span class="text_sapn">{{item.content}}</span>
                    <span>
                      <span class="text_sss">{{item.creatTime}}</span>
                      <span class="text_sxcvs" @click="gotohuati(item)">进入话题</span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="我回复的话题" name="second" style="height:630px;overflow-y:auto">
            <div v-if="!rlistdata">
              <el-empty description="暂无话题"></el-empty>
            </div>
            <div v-else>
              <div class="mems_two" v-for="item,index in rlistdata" :key="index">
                <div class="one_left">
                  <span>{{item.replyCount}}</span>
                </div>
                <div class="one_right">
                  <p class="one_r_title">
                    <span class="or_aaa">{{item.talkTitle}}</span>
                    <span class="or_bbb">
                      <i class="one_aaaa"></i>
                      <span>创建者：{{item.talkCreateUser}}</span>
                    </span>
                  </p>
                  <p class="one_r_text">
                    <span class="text_sapn">{{item.replyContent}}</span>
                    <span>
                      <span class="text_sss">{{item.talkReplyTime}}</span>
                      <span class="text_sxcvs" @click="gotohuati(item)">进入话题</span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </main>
      <!-- <footer>
        <el-button size="mini" type="primary">进入教研圈</el-button>
      </footer>-->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    memsdata: {
      type: Object,
      default: null,
    },
    replytiezi: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // tabs
      activeName: "first",
      // 我发布的列表
      clistdata: null,
      // 我回复的列表
      rlistdata: null,
      // 表单
      title: null,
      time: null,
    };
  },
  created() {
    this.queryMyReleaseTalk();
    this.queryMyReplyTalk();
  },
  methods: {
    // 我发布的
    async queryMyReleaseTalk() {
      console.log(this.title, this.time, "opia", this.memsdata);
      let endTime = null;
      let startTime = null;
      if (this.time !== null) {
        endTime = this.time[1];
        startTime = this.time[0];
      }
      const { data, status } = await this.$Http.queryMyReleaseTalk({
        endTime: endTime, //结束时间
        pageNo: 1, //页数
        pageSize: 100000, //每页数量
        researchId: this.memsdata.id, //圈子Id
        startTime: startTime, //开始时间
        title: this.title, //标题
      });
      if (status == 200) {
        console.log(data, status, "帖子");
        if (data == null) {
          this.clistdata = data;
          // this.title = null;
          // this.time = null;
        } else {
          this.clistdata = data.list;
          // this.title = null;
          // this.time = null;
        }
      }
    },
    // 我回复的
    async queryMyReplyTalk() {
      console.log(this.title, this.time, "opia2", this.memsdata);
      let endTime = null;
      let startTime = null;
      if (this.time !== null) {
        endTime = this.time[1];
        startTime = this.time[0];
      }
      const { data, status } = await this.$Http.queryMyReplyTalk({
        endTime: endTime, //结束时间
        pageNo: 1, //页数
        pageSize: 100000, //每页数量
        researchId: this.memsdata.id, //圈子Id
        startTime: startTime, //开始时间
        title: this.title, //标题
      });
      if (status == 200) {
        console.log(data, status, "帖子");
        if (data == null) {
          this.rlistdata = data;
          // this.title = null;
          // this.time = null;
        } else {
          this.rlistdata = data.list;
          // this.title = null;
          // this.time = null;
        }
      }
    },
    // 查询
    querylist() {
      console.log(this.activeName, "activeName");
      if (this.activeName == "first") {
        this.queryMyReleaseTalk();
      } else {
        this.queryMyReplyTalk();
      }
    },
    // 重置
    chognzhi() {
      console.log("chognzhi");
      this.title = null;
      this.time = null;
      this.queryMyReleaseTalk();
      this.queryMyReplyTalk();
    },
    // 进入话题
    gotohuati(row) {
      console.log(row, "进入话题");
      this.$router.push({
        path: "/circleintroduce",
        query: {
          site: 2,
          title: row,
          introId: this.memsdata.id,
          circlesite: 1,
        },
      });
    },
    // 切换tabs
    handleClick(tab, event) {
      console.log(tab, event, tab.index);
      if (tab.index == 0) {
        this.queryMyReleaseTalk();
      } else {
        this.queryMyReplyTalk();
      }
    },
  },
  watch: {
    memsdata: {
      handler(val, oldVal) {
        console.log(val, oldVal, "切换话题");
        this.queryMyReleaseTalk();
        this.queryMyReplyTalk();
      },
      deep: true,
    },
    replytiezi(val) {
      console.log(val, "关闭前的回调监听");
      if (val) {
        this.chognzhi();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.memsdig {
  .memsdig_center {
    nav {
      display: flex;
      justify-content: space-between;
      .div_one {
        display: flex;
        justify-content: flex-start;
      }
      .div_two {
      }
    }
    main {
      .mems_one {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
        .one_left {
          span {
            width: 40px;
            height: 24px;
            line-height: 21px;
            text-align: center;
            display: inline-block;
            background: url("../../../../../../assets/icon/replynum.png")
              no-repeat;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #8d9199;
          }
        }
        .one_right {
          // width: 280px;
          margin-left: 20px;
          .one_r_title {
            width: 600px;
            // width: 306px;
            // height: 16px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 600;
            color: #141516;
            // line-height: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .one_r_text {
            width: 810px;
            margin-top: 15px;
            display: flex;
            justify-content: space-between;
            .text_sapn {
              display: inline-block;
              max-width: 532px;
              // width: 306px;
              // height: 16px;
              font-size: 12px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #5d6066;
              // line-height: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .text_sss {
              display: inline-block;
              // max-width: 102px;
              // height: 16px;
              font-size: 12px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #8d9199;
              // line-height: 14px;
            }
            .text_sxcvs {
              display: inline-block;
              margin-left: 50px;
              // width: 48px;
              // height: 16px;
              font-size: 12px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #1977f9;
              // line-height: 14px;
              cursor: pointer;
            }
            .text_sxcvs:hover {
              color: rgba(25, 119, 249, 0.5);
            }
          }
        }
      }
      .mems_two {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
        .one_left {
          span {
            width: 40px;
            height: 24px;
            line-height: 21px;
            text-align: center;
            display: inline-block;
            background: url("../../../../../../assets/icon/replynum.png")
              no-repeat;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #8d9199;
          }
        }
        .one_right {
          // width: 280px;
          margin-left: 20px;
          .one_r_title {
            display: flex;
            justify-content: space-between;
            .or_aaa {
              display: inline-block;
              width: 580px;
              // width: 280px;
              // height: 18px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 600;
              color: #141516;
              // line-height: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .or_bbb {
              display: inline-block;
              .one_aaaa {
                display: inline-block;
                width: 16px;
                height: 16px;
                vertical-align: middle;
                background: url("../../../../../../assets/icon/creater.png")
                  no-repeat;
              }
              span {
                display: inline-block;
                width: 200px;
                // width: 36px;
                // height: 16px;
                font-size: 12px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #8d9199;
                vertical-align: middle;
                // line-height: 14px;
              }
            }
            // width: 600px;
            // // width: 306px;
            // // height: 16px;
            // font-size: 12px;
            // font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            // font-weight: 600;
            // color: #5d6066;
            // // line-height: 14px;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
          }
          .one_r_text {
            width: 810px;
            margin-top: 15px;
            display: flex;
            justify-content: space-between;
            .text_sapn {
              display: inline-block;
              max-width: 532px;
              // width: 306px;
              // height: 16px;
              font-size: 12px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #5d6066;
              // line-height: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .text_sss {
              display: inline-block;
              // max-width: 102px;
              // height: 16px;
              font-size: 12px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #8d9199;
              // line-height: 14px;
            }
            .text_sxcvs {
              display: inline-block;
              margin-left: 50px;
              // width: 48px;
              // height: 16px;
              font-size: 12px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #1977f9;
              // line-height: 14px;
              cursor: pointer;
            }
            .text_sxcvs:hover {
              color: rgba(25, 119, 249, 0.5);
            }
          }
        }
      }
    }
    // footer {
    //   margin-top: 10px;
    // }
  }
}
</style>