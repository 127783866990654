<template>
  <div class="newnotice">
    <div class="newnotice_center">
      <el-input v-model="topic" placeholder="请输入公告标题" size="mini"></el-input>
      <el-input type="textarea" v-model="areatext" :rows="20"></el-input>
      <!--       
        富文本编辑区域
      -->
      <!-- <myEditor @gethtml="htmltext" style="margin-top: 15px;"></myEditor> -->
      <div class="bens">
        <el-button type="primary" @click="saveCircleNotice" size="mini">发布</el-button>
        <el-button @click="cenclendialog" size="mini">关闭</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import myEditor from "../../../../../../components/myeditor.vue";
export default {
  props: {
    notid: {
      type: Number,
      default: null,
    },
  },
  components: {
    myEditor,
  },
  data() {
    return {
      //
      topic: null,
      areatext: null,
    };
  },
  methods: {
    //
    async saveCircleNotice() {
      console.log(this.topic, this.areatext, "表单");
      const { data, status } = await this.$Http.saveCircleNotice({
        content: this.areatext, //公告内容
        researchId: this.notid, //圈子id
        title: this.topic, //  公告标题
      });
      if (status == 200) {
        console.log(data, status, "发布公告");
        this.$emit("shutndialog");
        this.$message.success("发布成功");
        this.$store.commit("userinfo/postnotice", true);
        this.topic = null;
        this.areatext = null;
      }
    },
    //
    cenclendialog() {
      console.log("取消");
      this.topic = null;
      this.areatext = null;
      this.$emit("shutndialog", false);
    },
    //
    htmltext(val) {
      console.log(val, "富文本的html");
      this.areatext = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.newnotice {
  .newnotice_center {
    // .el-textarea {
    //   margin-top: 20px;
    //   ::v-deep .el-textarea__inner {
    //     background: #fafafc;
    //   }
    // }
    .bens {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>