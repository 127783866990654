<template>
  <div class="none">
    <div class="none_center">
      <!-- 未上线的圈子 -->
      <header>
        <el-button type="primary" size="mini" @click="submitdata(null)">批量提交审核</el-button>
        <el-button type="danger" size="mini" @click="deldata(null)">批量删除教研圈</el-button>
      </header>
      <main>
        <el-table :data="cirdata" stripe style="width: 100%" height="475" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50" align="center" fixed="left"></el-table-column>
          <el-table-column type="index" width="50" align="center" fixed="left" label="序号" :index="computeTableIndex"></el-table-column>
          <el-table-column fixed prop="circleName" label="教研圈名称" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="periodName" label="学段" width="125" show-overflow-tooltip></el-table-column>
          <el-table-column prop="gradeName" label="年级" width="125" show-overflow-tooltip></el-table-column>
          <el-table-column prop="subjectTypeName" label="学科类型" width="125"></el-table-column>
          <el-table-column prop="subjectName" label="学科" width="125"></el-table-column>
          <el-table-column prop="auditStatus" label="审核状态" width="125"></el-table-column>
          <el-table-column prop="auditMessage" label="审核意见" width="300" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column fixed="right" label="操作" width="200">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" content="提交审核" placement="top">
                <el-button
                  size="mini"
                  circle
                  @click="submitdata(scope.row.id)"
                  :disabled="scope.row.auditStatus=='提交待审核'||scope.row.auditStatus=='审核通过'"
                >
                  <i class="btn_sumbit"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="编辑教研圈" placement="top">
                <el-button
                  size="mini"
                  circle
                  @click="editdata(scope.row)"
                  :disabled="scope.row.auditStatus=='提交待审核'||scope.row.auditStatus=='审核通过'"
                >
                  <i class="btn_edit"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="查看教研圈" placement="top">
                <el-button size="mini" circle @click="viewdata(scope.row)">
                  <i class="btn_view"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="删除教研圈" placement="top">
                <el-button
                  size="mini"
                  circle
                  @click="deldata(scope.row.id)"
                  :disabled="scope.row.auditStatus=='提交待审核'||scope.row.auditStatus=='审核通过'"
                >
                  <i class="btn_del"></i>
                </el-button>
              </el-tooltip>
            </template>
            <!-- <el-button type="primary" size="mini" @click="submitdata(scope.row.id)">审核</el-button> -->
            <!-- <el-button type="success" size="mini" @click="editdata(scope.row)">编辑</el-button> -->
            <!-- <el-button type="warning" size="mini" @click="viewdata(scope.row)">查看</el-button> -->
            <!-- <el-button type="danger" size="mini" @click="deldata(scope.row.id)">删除</el-button> -->
          </el-table-column>
        </el-table>
      </main>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cirdata: {
      type: Array,
      default: null,
    },
    curp: {
      type: Number,
      default: 1,
    },
    psize: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      // 表格数据
      noneData: null,
      // 多选的数据
      multipleSelection: null,
    };
  },
  methods: {
    // 审核
    async submitdata(rowId) {
      console.log(rowId, "审核");
      let ids = [];
      if (rowId == null) {
        if (this.multipleSelection == null) {
          this.$message.warning("请选择要批量提交的教研圈");
          return;
        } else {
          for (let x = 0; x < this.multipleSelection.length; x++) {
            ids.push(this.multipleSelection[x].id);
          }
        }
      } else {
        ids.push(rowId);
      }

      this.$confirm("确定提交审核?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data, status } = await this.$Http.updateSaveCircleAudit({
            circleIdList: ids,
          });
          if (status == 200) {
            console.log(data, status, "教研圈提交审核");
            this.$emit("submit", true);
            this.$message({
              type: "success",
              message: "提交成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 删除
    async deldata(rowId) {
      console.log(rowId, "删除");
      let ids = [];
      if (rowId == null) {
        console.log(this.multipleSelection, "this.multipleSelection ");
        if (this.multipleSelection == null) {
          this.$message.warning("请选择要批量删除的教研圈");
          return;
        } else {
          for (let x = 0; x < this.multipleSelection.length; x++) {
            ids.push(this.multipleSelection[x].id);
          }
        }
      } else {
        ids.push(rowId);
      }

      this.$confirm("确定删除圈子?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data, status } = await this.$Http.deleteCricle({
            circleIdList: ids,
          });
          if (status == 200) {
            console.log(data, status, "教研圈删除");
            this.$emit("submit", true);
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 查看
    viewdata(row) {
      console.log(row);
      this.$router.push({
        path: "/circleintroduce",
        query: {
          introId: row.id,
          circlesite: 1,
        },
      });
    },
    // 编辑
    editdata(row) {
      console.log(row);
      this.$router.push({
        path: "/createcircle",
        query: { id: row.id },
      });
    },

    // 多选
    handleSelectionChange(val) {
      console.log(val, "xuzhogn");
      this.multipleSelection = val;
    },
    //监听index
    computeTableIndex(index) {
      // console.log(index, this.queryInfo.pagenum, this.queryInfo.pagesize, 'e')
      return (this.curp - 1) * this.psize + index + 1;
    },
  },
};
</script>
<style lang="scss" scoped>
.none {
  .none_center {
    header {
      background-color: #fff;
      // margin-top: 12px;
      padding: 0px 20px 18px 0;
    }
    main {
      i {
        display: inline-block;
        width: 19px;
        height: 18px;
      }
      .btn_sumbit {
        background: url("../../../../../assets/userinfo/submitbtn.png")
          no-repeat;
      }
      .btn_edit {
        background: url("../../../../../assets/userinfo/editbtn.png") no-repeat;
      }
      .btn_view {
        background: url("../../../../../assets/userinfo/viewbtn.png") no-repeat;
      }
      .btn_del {
        background: url("../../../../../assets/userinfo/delbtn.png") no-repeat;
      }
    }
  }
}
</style>