<template>
  <div class="noticedig">
    <div class="noticedig_center">
      <!-- 外圈弹框 -->
      <!-- <el-button @click="outerDig" size="mini">取 消</el-button> -->
      <el-button type="primary" @click="innerDig" size="mini">发布新公告</el-button>
      <el-button type="danger" @click="delnotice(null)" size="mini">批量删除公告</el-button>
      <el-table :data="noticeTab" @selection-change="handleSelectionChange" height="578" stripe>
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column type="index" width="50" align="center" fixed="left" label="序号" :index="computeTableIndex"></el-table-column>
        <el-table-column fixed prop="title" label="公告标题" align="center" width="450" show-overflow-tooltip></el-table-column>
        <el-table-column property="createTime" label="时间" width="200"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="查看公告" placement="top">
              <el-button size="mini" circle @click="viewdata(scope.row)">
                <i class="btn_view"></i>
              </el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除公告" placement="top">
              <el-button size="mini" circle @click="delnotice(scope.row)">
                <i class="btn_del"></i>
              </el-button>
            </el-tooltip>
            <!-- <el-button size="mini" type="primary" @click="viewdata(scope.row)">查看</el-button> -->
            <!-- <el-button size="mini" type="danger" @click="delnotice(scope.row)">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="curpage"
        :page-size="pagesize"
        layout="total, prev, pager, next, sizes, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    notid: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      //
      noticeTab: null, //表格数据
      //多选
      multipleSelection: null,
      // 分页
      curpage: 1,
      pagesize: 10,
      total: null,
    };
  },
  created() {
    this.queryCircleNotic();
  },
  methods: {
    // 获取圈子公告
    async queryCircleNotic() {
      const { data, status } = await this.$Http.queryCircleNotic({
        pageNo: this.curpage,
        pageSize: this.pagesize,
        researchId: this.notid,
      });
      if (status == 200) {
        if (data == null) {
          console.log(data, status, "获取圈子公告null");
          this.noticeTab = data;
          this.total = 0;
          this.$store.commit("userinfo/postnotice", false);
        } else {
          console.log(data, status, "获取圈子公告");
          this.noticeTab = data.list;
          this.total = data.total;
          this.$store.commit("userinfo/postnotice", false);
        }
      }
    },
    //查看圈子
    viewdata(row) {
      console.log(row, "查看圈子");
      this.$router.push({
        path: "/circlenotice",
        query: {
          id: row.id,
        },
      });
    },
    //删除公告
    async delnotice(row) {
      console.log(row, "删除公告", this.multipleSelection);
      let idlist = [];
      console.log();
      if (!row) {
        if (this.multipleSelection == null) {
          this.$message({
            type: "warning",
            message: "请选择要删除的公告",
          });
          return;
        }
        for (let x = 0; x < this.multipleSelection.length; x++) {
          idlist.push(this.multipleSelection[x].id);
        }
      } else {
        idlist.push(row.id);
      }
      this.$confirm("是否确定删除公告?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          console.log(idlist, "idlist");
          const { data, status } = await this.$Http.deleteNotice({
            noticeIdList: idlist,
          });
          if (status == 200) {
            // console.log(data, status, "删除公告");
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.queryCircleNotic();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //关闭外弹窗
    // outerDig() {
    // console.log("关闭弹窗");
    // this.$confirm("是否确定退出", "提示", {
    //   confirmButtonText: "确定",
    //   cancelButtonText: "取消",
    //   type: "warning",
    // })
    //   .then(() => {
    //     this.$emit("shutnotidig");
    //   })
    //   .catch(() => {
    //     return;
    //   });
    // },
    // 打开内弹窗
    innerDig() {
      this.$emit("opennotidig");
    },

    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagesize = val;
      this.queryCircleNotic();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.curpage = val;
      this.queryCircleNotic();
    },
    //
    //监听index
    computeTableIndex(index) {
      // console.log(index, this.queryInfo.pagenum, this.queryInfo.pagesize, 'e')
      return (this.curpage - 1) * this.pagesize + index + 1;
    },
  },
  computed: {
    ...mapState({
      notiType: (state) => state.userinfo.notiType,
    }),
  },
  watch: {
    notiType() {
      console.log(this.notiType, "是否为true，来刷新页面");
      if (this.notiType) {
        this.queryCircleNotic();
      } else {
        return;
      }
    },
    notid(val, oldVal) {
      console.log(this.notid, "是否切换了弹框", oldVal, val);
      this.noticeTab = null;
      this.queryCircleNotic();
    },
  },
};
</script>
<style lang="scss" scoped>
.noticedig {
  .noticedig_center {
    .el-table {
      margin-top: 20px;
    }
    i {
      display: inline-block;
      width: 19px;
      height: 18px;
    }
    .btn_view {
      background: url("../../../../../../assets/userinfo/viewbtn.png") no-repeat;
    }
    .btn_del {
      background: url("../../../../../../assets/userinfo/delbtn.png") no-repeat;
    }
    .el-pagination {
      display: flex;
      justify-content: center;
      margin-top: 15px;
    }
  }
}
</style>